import { Link } from 'react-router-dom'
import { Result, Button } from 'antd'
import Layout from '../../components/layout/Layout'
import { RoutePaths } from 'types/enums'

const NotFound: React.FC = () => (
  <Layout>
    <Result
      status='404'
      title='Page Not Found'
      subTitle='Sorry, the page you visited does not exist.'
      extra={
        <Link to={RoutePaths.ROOT}>
          <Button type='primary'>Back Home</Button>
        </Link>
      }
    />
  </Layout>
)

export default NotFound
