/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { groupBy } from 'lodash'
import {
  fetchClasses,
  fetchPolicies,
  fetchComparisonOptions,
  fetchFeatures,
  fetchPolicyContentsById,
  fetchSectionContentsById,
  saveComparisons,
  getSavedComparisons,
  removeSavedComparison,
  updateSavedComparison,
  emailSavedComparison,
  getSavedComparison,
  fetchBuyerGroups,
  approvePolicy,
} from './api'
import {
  CompareBy,
  Status,
  IFilter,
  PreviewFilter,
  CategoryFilter,
  IClassLine,
  IPolicy,
  ISectionFeatures,
  ISelectedClassCover,
  IComparisonOptions,
  IFeatureContents,
  ISelectionModal,
  IApprovePolicyModal,
  IBuyGroup,
  IImpersonation,
} from '../../types'
import { categories } from './data'

export * from './selectors'

export interface IComparisonState {
  filterOpen: boolean
  filter: IFilter
  selectedProductClassCover: ISelectedClassCover | null
  selectedPolicyContents: IFeatureContents[] | []
  selectedSectionCovers: IFeatureContents[] | []
  productClasses: IClassLine[] | [] | null
  selectionModal: ISelectionModal
  approvePolicyModal: IApprovePolicyModal
  comparisonPolicies: IPolicy[] | [] | null
  comparisonFeatures: ISectionFeatures[] | []
  buyGroups: IBuyGroup[] | []
  impersonation: IImpersonation
  savedComparisons: any
  comparisonOptions: IComparisonOptions | null
  comparisonStatus: Status
  comparisonError: string | null
}

export const initialState: IComparisonState = {
  filterOpen: false,
  filter: {
    preview: PreviewFilter.ICON,
    category: CategoryFilter.ALL,
    showNew: true,
    compareBy: CompareBy.COMPANY,
    effectiveDate: null,
    // effectiveDate: moment(new Date()).format(DateFormat.UTC),
    searchFeature: '',
    filterFeature: {},
    filterPolicy: {},
  },
  productClasses: categories,
  selectionModal: { open: false },
  approvePolicyModal: { open: false },
  comparisonPolicies: [],
  comparisonFeatures: [],
  comparisonOptions: null,
  selectedPolicyContents: [],
  selectedSectionCovers: [],
  selectedProductClassCover: null,
  savedComparisons: [],
  buyGroups: [],
  impersonation: {
    selectedCountry: null,
    selectedBuyGroup: null,
    countryBuyGroupOptions: [],
  },
  comparisonStatus: Status.IDLE,
  comparisonError: null,
}

export const fetchPolicyContentByIdAsync = createAsyncThunk(
  'comparison/fetch-policy-findings',
  fetchPolicyContentsById,
)
export const fetchSectionContentByIdAsync = createAsyncThunk(
  'comparison/fetch-feature-findings',
  fetchSectionContentsById,
)
export const fetchClassesAsync = createAsyncThunk('comparison/fetch-classes', fetchClasses)
export const fetchPoliciesAsync = createAsyncThunk('comparison/fetch-policies', fetchPolicies)
export const fetchFeaturesAsync = createAsyncThunk('comparison/fetch-features', fetchFeatures)
export const fetchComparisonOptionsAsync = createAsyncThunk(
  'comparison/fetch-comparison-options',
  fetchComparisonOptions,
)
export const saveComparisonsAsync = createAsyncThunk('comparison/save-comparison', saveComparisons)
export const getSavedComparisonsAsync = createAsyncThunk(
  'comparison/fetch-saved-comparisons',
  getSavedComparisons,
)
export const removeSavedComparisonAsync = createAsyncThunk(
  'comparison/remove-saved-comparison',
  removeSavedComparison,
)
export const emailSavedComparisonAsync = createAsyncThunk(
  'comparison/email-saved-comparison',
  emailSavedComparison,
)
export const getSavedComparisonAsync = createAsyncThunk(
  'comparison/get-saved-comparison',
  getSavedComparison,
)

export const updateSavedComparisonAsync = createAsyncThunk(
  'comparison/update-saved-comparison',
  updateSavedComparison,
)

export const fetchBuyerGroupsAsync = createAsyncThunk(
  'comparison/fetch-buyer-groups',
  fetchBuyerGroups,
)

export const approvePolicyAsync = createAsyncThunk('comparison/approve-policy', approvePolicy)

export const comparisonSlice = createSlice({
  name: 'comparison',
  initialState,
  reducers: {
    showFilter: (state) => {
      state.filterOpen = true
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      state.filter = payload
    },
    updateSelectionModal: (state, { payload }: PayloadAction<ISelectionModal>) => {
      state.selectionModal = payload
    },
    updateApprovePolicyModal: (state, { payload }: PayloadAction<IApprovePolicyModal>) => {
      state.approvePolicyModal = payload
    },
    selectProductClassCover: (state, { payload }: PayloadAction<ISelectedClassCover>) => {
      state.selectedProductClassCover = payload
    },
    removeProductClassCover: (state) => {
      state.selectedProductClassCover = null
    },
    selectPolicyContents: (state, { payload }) => {
      state.selectedPolicyContents = payload
    },
    removeAllSelections: (state) => {
      state.selectedSectionCovers = []
      state.selectedPolicyContents = []
    },
    removeSelectedPolicyCoverDetails: (state, { payload }: PayloadAction<number | string>) => {
      const filtered = state.selectedPolicyContents.filter(
        (policy: IFeatureContents) => policy.id !== payload,
      )
      state.selectedPolicyContents = filtered
    },
    resetComparison: (state) => {
      state.filter = {
        ...state.filter,
        category: CategoryFilter.ALL,
        searchFeature: '',
        filterFeature: {},
        filterPolicy: {},
      }
      state.selectedPolicyContents = initialState.selectedPolicyContents
      state.selectedSectionCovers = initialState.selectedSectionCovers
      state.comparisonFeatures = initialState.comparisonFeatures
      state.comparisonOptions = initialState.comparisonOptions
      state.comparisonPolicies = initialState.comparisonPolicies
    },
    updateSelectedSectionCovers: (state, { payload }: PayloadAction<IFeatureContents[]>) => {
      state.selectedSectionCovers = payload
    },
    removeSelectedSectionCover: (state, { payload }: PayloadAction<number | string>) => {
      const filtered = state.selectedSectionCovers.filter(
        (cover: IFeatureContents) => cover.id !== payload,
      )
      state.selectedSectionCovers = filtered
    },
    swapSelectedPolicyPosition: (state, { payload }) => {
      const swapped = [...state.selectedPolicyContents]
      swapped[payload.to] = state.selectedPolicyContents[payload.from]
      swapped[payload.from] = state.selectedPolicyContents[payload.to]
      state.selectedPolicyContents = swapped
    },
    swapSelectedCoverPosition: (state, { payload }) => {
      const swapped = [...state.selectedSectionCovers]
      swapped[payload.to] = state.selectedSectionCovers[payload.from]
      swapped[payload.from] = state.selectedSectionCovers[payload.to]
      state.selectedSectionCovers = swapped
    },
    setImpersonation: (state, { payload }) => {
      state.impersonation = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClassesAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(fetchClassesAsync.fulfilled, (state, { payload }: PayloadAction<IClassLine[]>) => {
        state.comparisonStatus = Status.IDLE
        state.productClasses = payload
      })
      .addCase(fetchClassesAsync.rejected, (state, action) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = action.error.message || 'Failed to fetch classes'
      })
      .addCase(fetchPoliciesAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(fetchPoliciesAsync.fulfilled, (state, { payload }: PayloadAction<IPolicy[]>) => {
        state.comparisonStatus = Status.IDLE
        state.comparisonPolicies = payload
      })
      .addCase(fetchPoliciesAsync.rejected, (state, action) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = action.error.message || 'Failed to fetch policies'
      })
      .addCase(fetchFeaturesAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(
        fetchFeaturesAsync.fulfilled,
        (state, { payload }: PayloadAction<ISectionFeatures[]>) => {
          state.comparisonStatus = Status.IDLE
          state.comparisonFeatures = payload
        },
      )
      .addCase(fetchFeaturesAsync.rejected, (state, action) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = action.error.message || 'Failed to fetch features'
      })
      .addCase(fetchComparisonOptionsAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(
        fetchComparisonOptionsAsync.fulfilled,
        (state, { payload }: PayloadAction<IComparisonOptions>) => {
          state.comparisonStatus = Status.IDLE
          state.comparisonOptions = payload
        },
      )
      .addCase(fetchComparisonOptionsAsync.rejected, (state, action) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = action.error.message || 'Failed to fetch comparison options'
      })
      .addCase(fetchPolicyContentByIdAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(
        fetchPolicyContentByIdAsync.fulfilled,
        (state, { payload }: PayloadAction<IFeatureContents>) => {
          state.comparisonStatus = Status.IDLE
          state.selectedPolicyContents[payload.slot] = payload
        },
      )
      .addCase(fetchPolicyContentByIdAsync.rejected, (state, action) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = action.error.message || 'Failed to fetch feature contents'
      })
      .addCase(fetchSectionContentByIdAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(
        fetchSectionContentByIdAsync.fulfilled,
        (state, { payload }: PayloadAction<IFeatureContents>) => {
          state.comparisonStatus = Status.IDLE
          state.selectedSectionCovers[payload.slot] = payload
        },
      )
      .addCase(fetchSectionContentByIdAsync.rejected, (state, action) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = action.error.message || 'Failed to fetch section contents'
      })
      .addCase(saveComparisonsAsync.fulfilled, (state) => {
        state.comparisonStatus = Status.IDLE
      })
      .addCase(saveComparisonsAsync.rejected, (state, action) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = action.error.message || 'Failed to save comparison'
      })
      .addCase(saveComparisonsAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(getSavedComparisonsAsync.fulfilled, (state, { payload }) => {
        state.comparisonStatus = Status.IDLE
        state.savedComparisons = payload
      })
      .addCase(getSavedComparisonsAsync.rejected, (state, { error }) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = error.message || 'Failed to fetch saved comparisons'
      })
      .addCase(getSavedComparisonsAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(getSavedComparisonAsync.fulfilled, (state, { payload }) => {
        const { mode, effective_date, feature_groups, policies, covers, findings, filter } = payload
        state.comparisonStatus = Status.IDLE
        state.filter.compareBy = mode === 'policy' ? CompareBy.COMPANY : CompareBy.FEATURE
        state.filter.effectiveDate = effective_date
        state.filter.category = filter

        const allClasses = state.productClasses?.map((cl) => cl.classes).flat()
        const foundClass =
          allClasses &&
          allClasses.find((productClass) =>
            productClass.covers.some((cover) => cover.id === covers[0].id),
          )
        const loadedSelectedClassesCover = {
          className: foundClass?.name,
          classId: foundClass?.id,
          coverId: covers.map((cover) => cover.id),
          coverType: covers.map((cover) => cover.name),
        }
        state.selectedProductClassCover = loadedSelectedClassesCover

        if (mode === 'policy') {
          const filterFeatures = {}
          feature_groups.forEach((cover) =>
            cover.features.forEach((feature) => {
              if (!filterFeatures[feature.section]) {
                filterFeatures[feature.section] = []
              }
              filterFeatures[feature.section].push(feature.id)
            }),
          )
          state.filter.filterFeature = filterFeatures
          const loadedSelectedPolicyContents = policies.map((policy, slot) => ({
            id: policy.id,
            slot,
            policy: policy,
            featureContents: findings.filter((finding) => finding.policy_id === policy.id),
          }))
          state.selectedPolicyContents = loadedSelectedPolicyContents
        }

        if (mode === 'feature') {
          const filterPolicies = {}
          policies.forEach((policy) => {
            if (!filterPolicies[policy.insurer.name]) {
              filterPolicies[policy.insurer.name] = []
            }
            filterPolicies[policy.insurer.name].push(policy.id)
          })
          state.filter.filterPolicy = filterPolicies
          const loadedSelectedSectionContents = feature_groups.map((feature_group) => {
            return feature_group.features.map((feature) => {
              return {
                id: feature.id,
                featureContents: findings.filter((finding) => finding.feature_id === feature.id),
              }
            })
          })
          state.selectedSectionCovers = loadedSelectedSectionContents.flat()
        }
      })
      .addCase(getSavedComparisonAsync.rejected, (state, { error }) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = error.message || 'Failed to fetch saved comparisons'
      })
      .addCase(getSavedComparisonAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(removeSavedComparisonAsync.fulfilled, (state) => {
        state.comparisonStatus = Status.IDLE
      })
      .addCase(removeSavedComparisonAsync.rejected, (state, { error }) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = error.message || 'Failed to fetch saved comparisons'
      })
      .addCase(removeSavedComparisonAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(updateSavedComparisonAsync.fulfilled, (state) => {
        state.comparisonStatus = Status.IDLE
      })
      .addCase(updateSavedComparisonAsync.rejected, (state, { error }) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = error.message || 'Failed to fetch saved comparisons'
      })
      .addCase(updateSavedComparisonAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(emailSavedComparisonAsync.fulfilled, (state) => {
        state.comparisonStatus = Status.IDLE
      })
      .addCase(emailSavedComparisonAsync.rejected, (state, { error }) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = error.message || 'Failed to fetch saved comparisons'
      })
      .addCase(emailSavedComparisonAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(
        fetchBuyerGroupsAsync.fulfilled,
        (state, { payload }: PayloadAction<IBuyGroup[]>) => {
          // initialize buyer group on the first load
          if (state.buyGroups.length < 2) {
            const countryGroups = groupBy(payload, 'country_name')
            const options =
              countryGroups['Australia'] &&
              countryGroups['Australia'].map((bg) => ({ value: bg.id, label: bg.name }))
            state.impersonation = {
              ...state.impersonation,
              countryBuyGroupOptions: options,
            }
            state.comparisonStatus = Status.IDLE
          }
          state.buyGroups = payload
        },
      )
      .addCase(fetchBuyerGroupsAsync.rejected, (state, { error }) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = error.message || 'Failed to fetch saved comparisons'
      })
      .addCase(fetchBuyerGroupsAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
      .addCase(approvePolicyAsync.fulfilled, (state) => {
        state.comparisonStatus = Status.IDLE
      })
      .addCase(approvePolicyAsync.rejected, (state, { error }) => {
        state.comparisonStatus = Status.ERROR
        state.comparisonError = error.message || 'Failed to fetch saved comparisons'
      })
      .addCase(approvePolicyAsync.pending, (state) => {
        state.comparisonStatus = Status.LOADING
      })
  },
})

export const {
  showFilter,
  updateFilter,
  updateSelectionModal,
  updateApprovePolicyModal,
  selectProductClassCover,
  selectPolicyContents,
  removeSelectedPolicyCoverDetails,
  resetComparison,
  updateSelectedSectionCovers,
  removeSelectedSectionCover,
  swapSelectedPolicyPosition,
  swapSelectedCoverPosition,
  setImpersonation,
  removeProductClassCover,
  removeAllSelections,
} = comparisonSlice.actions

export default comparisonSlice.reducer
