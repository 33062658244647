/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { Spin } from 'antd'
import { useAppDispatch } from 'store'
import { logout } from 'slices/auth'
import Layout from 'components/layout/Layout'
import auth from 'services/auth'

import './login.less'

const Logout: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(logout())

    if (!auth.isLoggedIn) {
      navigate('/')
    }
  }, [auth.isLoggedIn])

  return (
    <Layout>
      <div className='login-container'>
        <Spin tip='navigating to homepage..' />
      </div>
    </Layout>
  )
}

export default Logout
