/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import auth from 'services/auth'
import { Session } from 'types/enums'

export const useIdleTimer = () => {
  let timeout: NodeJS.Timeout | null = null

  const restartAutoReset = () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      auth.logout()
    }, 1000 * 60 * Session.TIMEOUT)
  }

  const onMouseMove = () => {
    restartAutoReset()
  }

  useEffect(() => {
    restartAutoReset()
    window.addEventListener('mousemove', onMouseMove)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
        window.removeEventListener('mousemove', onMouseMove)
      }
    }
  }, [auth.isLoggedIn])

  return null
}
