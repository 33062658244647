import { useState } from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import { useWindowScrollTop, useWindowResize } from 'components/hooks'
import HeaderMenu from './components/menu/HeaderMenu'
import UserActions from './components/user-actions/UserActions'
import { RoutePaths } from 'types/enums'

import './header.less'

const Header: React.FC = () => {
  const { visible, shadow } = useWindowScrollTop()
  const { isMobile, isTablet } = useWindowResize()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className={cx('site-header', { visible, shadow })}>
      <div className='site-header-container'>
        <div className='site-header-logo'>
          <Link to={RoutePaths.ROOT}>
            <img src={`/assets/logo.png`} alt='logo' />
          </Link>
        </div>

        {isTablet || isMobile ? (
          <>
            <FontAwesomeIcon
              icon={mobileMenuOpen ? faClose : faBars}
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            />
            <div className={cx('site-header-mobile-actions', { open: mobileMenuOpen })}>
              <HeaderMenu />
              <UserActions />
            </div>
          </>
        ) : (
          <div className={cx('site-header-actions')}>
            <HeaderMenu />
            <UserActions />
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
