import { message } from 'antd'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import { RoutePaths, Session } from 'types/enums'

export const SSO_BASE_URL = process.env.REACT_APP_SSO_BASE_URL
const LMI_PROJECT_ID = process.env.REACT_APP_PROJECT_ID

const setAuthorizationHeader = () => {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(Session.KEY)}`
  axios.defaults.headers['Api-version'] = 'v1'
  axios.interceptors.response.use(
    (res) => res,
    async (err) => {
      const status = err && err.response ? err.response.status : null
      switch (status) {
        case StatusCodes.UNAUTHORIZED:
          message.destroy()
          message.error('Session expired. Please login again')
          window.location.replace('/')
          return Promise.reject(err)
        case StatusCodes.TOO_MANY_REQUESTS:
          message.destroy()
          message.error('Too many request. Please try later')
          return Promise.reject(err)
        default:
          return Promise.reject(err)
      }
    },
  )
}

export default {
  get session() {
    return {
      token: localStorage.getItem(Session.KEY),
      username: localStorage.getItem(Session.USERNAME),
      expiry: localStorage.getItem(Session.EXPIRY),
    }
  },
  get encryptedData() {
    return localStorage.getItem(Session.ENCRYPTED_DATA)
  },
  get isLoggedIn() {
    const expiry = localStorage.getItem(Session.EXPIRY)
    if (!expiry || +expiry <= Date.now()) return false
    return !!localStorage.getItem(Session.KEY)
  },
  get callbackLoginUrl() {
    return `${window.location.origin}${RoutePaths.LOGIN}`
  },
  get callbackLogoutUrl() {
    return `${window.location.origin}${RoutePaths.LOGOUT}`
  },
  get ssoLoginUrl() {
    return `${SSO_BASE_URL}/?projectId=${LMI_PROJECT_ID}&callbackUrl=${this.callbackLoginUrl}`
  },
  get ssoLogoutUrl() {
    return `${SSO_BASE_URL}/Signout?callbackUrl=${this.callbackLogoutUrl}`
  },
  get disclaimerAgreed() {
    return localStorage.getItem(Session.DISCLAIMER_AGREED)
  },
  get supportServicesKey() {
    const linkgenKey = localStorage.getItem(Session.LINKGEN_KEY)
    const encryptedData = localStorage.getItem(Session.ENCRYPTED_DATA)

    if (linkgenKey && encryptedData) {
      return `?key=${linkgenKey}&EncryptedData=${encryptedData}`
    }
    return null
  },
  login(token, username, expiry) {
    if (!token) throw new Error('Invalid token')
    localStorage.setItem(Session.KEY, token)
    localStorage.setItem(Session.USERNAME, username)
    localStorage.setItem(Session.EXPIRY, '' + Date.now() + expiry * 1000)
    setAuthorizationHeader()
  },
  logout() {
    localStorage.removeItem(Session.KEY)
    localStorage.removeItem(Session.USERNAME)
    localStorage.removeItem(Session.EXPIRY)
    localStorage.removeItem(Session.ENCRYPTED_DATA)
    localStorage.removeItem(Session.LINKGEN_KEY)
    window.location.replace(RoutePaths.ROOT)
  },
  setImpersonateBuyGroupHeader(buyGroupId) {
    axios.defaults.headers['x-impersonate-buy-group'] = buyGroupId
  },
  removeImpersonateBuyGroupHeader() {
    delete axios.defaults.headers['x-impersonate-buy-group']
  },
  setDisclaimerAgreed() {
    localStorage.setItem(Session.DISCLAIMER_AGREED, 'agreed')
  },
  setAuthorizationHeader,
}
