import { useState, useEffect } from 'react'
import { useBlocker } from 'react-router'

const useNavigationBlocker = (refValue?) => {
  const [value, setValue] = useState('')
  // Block navigating elsewhere when data has been entered into the input
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      value !== '' && currentLocation.pathname !== nextLocation.pathname,
  )
  useEffect(() => {
    setValue('blocked')
  }, [refValue])

  return blocker
}

export default useNavigationBlocker
