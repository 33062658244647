import { Modal, Button } from 'antd'
import lmiDayLogo from 'assets/images/au-day-logo.png'
import { useWindowResize } from 'components/hooks'
import './disclaimer-modal.less'

interface DisclaimerModalProps {
  onOk: VoidFunction
  onCancel: VoidFunction
  visible: boolean
}

const DisclaimerModal: React.FC<DisclaimerModalProps> = ({ onOk, onCancel, visible }) => {
  const { width } = useWindowResize()
  const isMobile = width && width <= 768

  return (
    <Modal
      title='Disclaimer'
      width={isMobile ? '85%' : '60%'}
      open={visible}
      destroyOnClose={true}
      onCancel={onCancel}
      wrapClassName='disclaimer-modal'
      footer={
        <div className='disclaimer-modal-footer'>
          <Button type='primary' size='large' onClick={onCancel}>
            Disagree
          </Button>
          <Button type='primary' size='large' onClick={onOk}>
            Agree
          </Button>
        </div>
      }
    >
      <div className='disclaimer-modal-text'>
        <div className='logo-holder'>
          <img src={lmiDayLogo} alt='LMI AU Day Logo' />
        </div>
        <h2>Please read the Disclaimer and &apos;Agree&apos; at the bottom</h2>
        <p>
          Please read the Disclaimer and &apos; Agree&apos; at the bottom. The information provided
          in this website has been prepared as a guide, and it is not intended to be exhaustive.
        </p>
        <p>
          While the utmost care has been taken in the preparation of the site, it should not be used
          or relied upon as a substitute for detailed advice or as a basis for a business, financial
          or insurance decision.
        </p>
        <p>
          By agreeing to this condition, which is done by selecting the &quot;Agree&quot; box, I
          accept that Policy Comparison International Pty Ltd cannot be held responsible for any
          loss occasioned to any person acting or refraining from action as a result of the material
          included in this site or reproduced from it.
        </p>
        <p>
          Further I acknowledge that the views expressed by contributors and advertisers are not
          necessarily those of Policy Comparison International Pty Ltd and again accept that Policy
          Comparison International Pty Ltd cannot be held responsible for any loss occasioned to any
          person acting or refraining from action as a result of the material included in this site
          or reproduced from it.
        </p>
      </div>
    </Modal>
  )
}

export default DisclaimerModal
