import React, { Suspense, lazy } from 'react'
import {
  Route,
  RouterProvider,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import auth from 'services/auth'
import Spinner from 'components/spinner/Spinner'
import NotFound from 'scenes/not-found'
import Login from 'scenes/login/Login'
import Logout from 'scenes/login/Logout'
import ErrorBoundary from 'components/error-boundary/ErrorBoundary'
import { useIdleTimer } from 'components/hooks'
import { RoutePaths } from './types'

const Home = lazy(() => import('scenes/home/Home'))
const Comparison = lazy(() => import('scenes/comparison/Comparison'))
const Dashboard = lazy(() => import('scenes/dashboard/Dashboard'))

interface PrivateRouteProps {
  redirectPath?: string
  component: React.ComponentType
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  redirectPath = RoutePaths.LOGIN,
  component: RouteComponent,
}) => {
  if (!auth.isLoggedIn) {
    return <Navigate to={redirectPath} replace />
  }
  useIdleTimer()
  return (
    <ErrorBoundary>
      <RouteComponent />
    </ErrorBoundary>
  )
}

const routes = createRoutesFromElements(
  <>
    <Route path={RoutePaths.ROOT} element={<Home />} />
    <Route path={RoutePaths.LOGIN} element={<Login />} />
    <Route path={RoutePaths.LOGOUT} element={<Logout />} />
    <Route path={RoutePaths.DASHBOARD} element={<PrivateRoute component={Dashboard} />} />
    <Route path={RoutePaths.COMPARISON} element={<PrivateRoute component={Comparison} />} />
    <Route path={RoutePaths.PRIVATE} element={<PrivateRoute component={Comparison} />} />
    <Route path={RoutePaths.REST} element={<NotFound />} />
  </>,
)

const router = createBrowserRouter(routes)

const AppRoutes = () => (
  <Suspense fallback={<Spinner />}>
    <RouterProvider router={router} />
  </Suspense>
)

export default AppRoutes
