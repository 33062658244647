import { Reducer } from 'redux'
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux'
import { configureStore, createAsyncThunk, combineReducers } from '@reduxjs/toolkit'

import appReducer, { IAppState } from 'slices/app'
import authReducer, { IAuthState } from 'slices/auth'
import comparisonReducer, { IComparisonState } from 'slices/comparison'
import reportReducer, { IReportState } from 'slices/report'

export interface IRootState {
  app: IAppState
  auth: IAuthState
  comparison: IComparisonState
  report: IReportState
}

export const rootReducer: Reducer<IRootState> = combineReducers<IRootState>({
  app: appReducer,
  auth: authReducer,
  comparison: comparisonReducer,
  report: reportReducer,
})

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
  rejectValue: string
  //extra: { s: string; n: number }
}>()

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
})

export const setupStore = (preloadedState) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
  })
