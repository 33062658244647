/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Spin } from 'antd'
import { useAppDispatch } from 'store'
import { logout } from 'slices/auth'
import auth from 'services/auth'

import Layout from 'components/layout/Layout'
import DisclaimerModal from './components/discliamer-modal/DisclaimerModal'
import { RoutePaths } from 'types/enums'

import './login.less'

const Login: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [disclaimerModalOpen, setDisclaimerModalOpen] = useState(false)

  const shouldRedirectToSSO: () => boolean = () => {
    // check logic here before navigting to SSO
    return true
  }

  const onAgree: VoidFunction = () => {
    auth.setDisclaimerAgreed()
    setDisclaimerModalOpen(false)
  }

  const onCancel: VoidFunction = () => {
    dispatch(logout())
    window.location.replace(auth.ssoLogoutUrl)
  }

  useEffect(() => {
    if (auth.isLoggedIn) {
      if (!auth.disclaimerAgreed) {
        setDisclaimerModalOpen(true)
      } else {
        navigate(RoutePaths.DASHBOARD)
      }
    }

    if (!auth.isLoggedIn) {
      if (shouldRedirectToSSO()) {
        window.location.replace(auth.ssoLoginUrl)
      }
    }
  }, [auth.disclaimerAgreed, auth.isLoggedIn])

  return (
    <Layout>
      <div className='login-container'>
        <DisclaimerModal visible={disclaimerModalOpen} onOk={onAgree} onCancel={onCancel} />
        <Spin tip='Loading login...' />
      </div>
    </Layout>
  )
}

export default Login
