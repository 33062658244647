import { Link } from 'react-router-dom'
import { Button, Avatar } from 'antd'

import auth from 'services/auth'
import { RoutePaths } from 'types/enums'
import './user-actions.less'

const MEDIA_URL = process.env.REACT_APP_MEDIA_BASE_URL

const UserActions: React.FC = () => {
  return (
    <div className='site-header-user-actions'>
      {auth.isLoggedIn ? (
        <div className='site-header-user'>
          <Avatar className='site-header-user-avatar' shape='square'>
            {auth.session.username && auth.session.username[0]}
          </Avatar>
          <span className='site-header-user-name'>{auth.session.username}</span>
          <Button className='site-header-btn-logout' type='link'>
            <a href={auth.ssoLogoutUrl}>LOG OUT</a>
          </Button>
          {false && (
            <Button type='default'>
              <a href={MEDIA_URL} target='_blank' rel='noopener noreferrer'>
                Classic PolicyComparison
              </a>
            </Button>
          )}
        </div>
      ) : (
        <Link to={RoutePaths.LOGIN}>
          <Button className='login-btn' type='text' size='large'>
            LOG IN
          </Button>
        </Link>
      )}
    </div>
  )
}

export default UserActions
