import { useState, useEffect } from 'react'

const useWindowScrollTop = () => {
  const [visible, setVisible] = useState<boolean>(true)
  const [shadow, setShadow] = useState<boolean>(false)
  const [scrolled, setScrolled] = useState<boolean>(false)

  const scrollTop = () => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    } catch (error) {
      window.scrollTo(0, 0)
    }
  }

  useEffect(() => {
    let oldScrollTop = 0

    const onScroll = () => {
      const scrollTop = document.body.scrollTop || document.documentElement.scrollTop
      setVisible(!(scrollTop >= oldScrollTop && scrollTop > 160))
      setScrolled(scrollTop > 60)
      setShadow(scrollTop > 10)

      oldScrollTop = scrollTop
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [scrolled])

  return { visible, shadow, scrollTop, scrolled }
}

export default useWindowScrollTop
