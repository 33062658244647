/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { message } from 'antd'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusCodes } from 'http-status-codes'
import { Session } from 'types/enums'
import { logout } from 'slices/auth'
import { loginWithEncryptedDataAsync, fetchUserAccountAsync } from 'slices/auth/api'
import auth from 'services/auth'

const BASE_API_URL: string = process.env.REACT_APP_BASE_API_URL || ''

export interface IAppState {
  initialized: boolean
  theme: string
  newsFeed: any
}

export const initialState: IAppState = {
  initialized: false,
  theme: 'default',
  newsFeed: null,
}

class AccessRequestUrl {
  url: URL

  constructor() {
    const windowLocation = window.location.toString()
    this.url = new URL(windowLocation)
  }

  get linkgenToken() {
    const encryptedData =
      this.url.searchParams.get('EncryptedData') || this.url.searchParams.get('amp;EncryptedData')
    const key = this.url.searchParams.get('key')
    if (encryptedData && key) {
      return encryptedData
    }
    return null
  }

  get token() {
    return this.url.searchParams.get('token') || localStorage.getItem(Session.KEY)
  }
}

export const checkLiveness = async () => {
  const url = `${BASE_API_URL}health/liveness`
  try {
    const res = await axios.get(url, { headers: { 'Api-Version': 'v1' } })
    console.log(res.data)
  } catch (err: any) {
    console.log(err)
  }
}

export const initialize = () => async (dispatch) => {
  if (window.location.pathname.includes('//')) {
    const replacedPath =
      window.location.pathname.replace(/(?<!:)\/+/gm, '/') + window.location.search
    window.location.replace(replacedPath)
  }
  if (window.location.pathname.toLowerCase().includes('linkgen.ashx')) {
    const replacedPath = auth.callbackLoginUrl + window.location.search
    window.location.replace(replacedPath)
  }

  // checkLiveness()

  const url = new AccessRequestUrl()

  axios.interceptors.response.use(
    (res) => res,
    async ({ response }) => {
      const status = response ? response.status : null
      switch (status) {
        case StatusCodes.UNAUTHORIZED:
          message.destroy()
          message.error('Session expired. Please login again')
          dispatch(logout())
          return Promise.reject(response)
        default:
          return Promise.reject(response)
      }
    },
  )

  let token = url.linkgenToken
  if (token) {
    await dispatch(loginWithEncryptedDataAsync({ token, grantType: 'LinkgenUrl' }))
    await dispatch(fetchUserAccountAsync(token))
  } else {
    token = url.token
    if (token) {
      localStorage.setItem(Session.KEY, token)
      auth.setAuthorizationHeader()
      await dispatch(fetchUserAccountAsync(token))
    }
  }
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initializeApp: (state: IAppState) => {
      state.initialized = true
    },
    setTheme: (state: IAppState, { payload }: PayloadAction<string>) => {
      state.theme = payload
    },
    setNews: (state: IAppState, { payload }: PayloadAction<any>) => {
      state.newsFeed = payload
    },
  },
})

export const { initializeApp, setTheme, setNews } = appSlice.actions
export default appSlice.reducer
