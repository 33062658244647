/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios'
import { message } from 'antd'
import { StatusCodes } from 'http-status-codes'
import { IPolicy, ISectionFeatures, IFeatureContent, IClassLine } from 'types/interfaces'
import { FileFormat, UserActivityLog } from 'types/enums'

const MEDIA_URL: string = process.env.REACT_APP_MEDIA_BASE_URL || ''
const BASE_API_URL: string = process.env.REACT_APP_BASE_API_URL || ''
const MEDIA_CENTRE_URL = process.env.REACT_APP_MEDIA_CENTRE_URL || ''

const coverIdQueryUrl = (coverId) => {
  let queryString = '?'
  if (Array.isArray(coverId)) {
    coverId.forEach((id) => (queryString += `cover_id=${id}&`))
  } else {
    queryString += `cover_id=${coverId}`
  }
  return queryString
}

export const fetchBuyerGroups = async () => {
  try {
    const { data } = await axios.get(`${BASE_API_URL}buy-groups`)
    return data
  } catch (err: any) {
    const reason = 'Failed to fetch buyer groups'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const fetchClasses = async (country = 'au') => {
  try {
    const params = { country: country.toLowerCase() }
    const { data }: { data: IClassLine[] } = await axios.get(`${BASE_API_URL}categories`, {
      params,
    })
    return data
  } catch (err: any) {
    const reason = 'Failed to fetch classes'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const fetchPolicies = async ({
  cover_id,
  date,
}: {
  cover_id: number | number[]
  date?: string | null
}) => {
  let effectiveDate = new Date().toJSON().slice(0, 10)
  if (date) {
    effectiveDate = date
  }
  const url = `${BASE_API_URL}policies${coverIdQueryUrl(cover_id)}&date=${effectiveDate}`

  try {
    const { data }: { data: IPolicy[] } = await axios.get(url)
    return data
  } catch (err: any) {
    const reason = 'Failed to fetch policies'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const fetchLatestPolicies = async (count) => {
  try {
    const res = await axios.get(`${BASE_API_URL}policies/latest?count=${count}`)
    return res.data
  } catch (err: any) {
    const reason = 'Failed to fetch latest policies'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const fetchFeatures = async (cover_id: number | number[]) => {
  const url = `${BASE_API_URL}features${coverIdQueryUrl(cover_id)}`
  try {
    const { data }: { data: ISectionFeatures[] } = await axios.get(url)
    return data
  } catch (err: any) {
    const reason = 'Failed to fetch features'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const fetchMediaCentreLogo = (url: string) => {
  if (url && url.includes('/media')) {
    return `${MEDIA_CENTRE_URL}${url}`
  } else {
    return `${MEDIA_URL}/${url}`
  }
}

export const fetchComparisonOptions = async (cover_id: number | number[]) => {
  const url = `${BASE_API_URL}comparison-options${coverIdQueryUrl(cover_id)}`
  try {
    const res = await axios.get(url)
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

export const fetchPolicyContentsById = async ({
  policy,
  slot,
}: {
  policy: IPolicy
  slot: number
}) => {
  const params = { id: policy.id }
  try {
    const { data }: { data: IFeatureContent[] } = await axios.get(
      `${BASE_API_URL}policies/${policy.id}/findings`,
      {
        params,
      },
    )
    return {
      id: policy.id,
      policy,
      slot,
      featureContents: data,
    }
  } catch (err: any) {
    const reason = 'Failed to fetch policy contents'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const fetchSectionContentsById = async ({ id, slot }: { id: number; slot: number }) => {
  const params = { id }
  try {
    const { data }: { data: IFeatureContent[] } = await axios.get(
      `${BASE_API_URL}features/${id}/findings`,
      {
        params,
      },
    )
    return { id: id, slot, featureContents: data }
  } catch (err: any) {
    const reason = 'Failed to fetch feature contents'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const exportComparisons = async (params) => {
  const url = `${BASE_API_URL}export/comparisons`
  try {
    const res = await axios.post(url, params, {
      responseType: 'arraybuffer',
    })
    return res
  } catch (err: any) {
    const reason = 'Failed to exoprt comparisons'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

// Saved Comparisons
export const saveComparisons = async (params) => {
  const url = `${BASE_API_URL}comparisons`
  try {
    await axios.post(url, params)
    message.success('Comparison has been saved')
    return Promise.resolve()
  } catch (err: any) {
    const status = err ? err.status : null
    const reason = 'Failed to save comparisons'
    switch (status) {
      case StatusCodes.CONFLICT:
        message.error(`${reason}, no change found`)
        throw new Error(err)
      case StatusCodes.NOT_FOUND:
        message.error(reason)
        throw new Error(err)
      default:
        message.error(reason)
        throw new Error(err)
    }
  }
}

export const getSavedComparisons = async (q?: string) => {
  const params = { q }
  try {
    const { data } = await axios.get(`${BASE_API_URL}comparisons`, {
      params,
    })
    return data
  } catch (err: any) {
    const reason = 'Failed to fetch saved comparisons'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const getSavedComparison = async (id) => {
  try {
    const { data } = await axios.get(`${BASE_API_URL}comparisons/${id}`)
    return data
  } catch (err: any) {
    const reason = 'Failed to fetch saved comparison'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const updateSavedComparison = async ({ id, name }: { id: string; name: string }) => {
  try {
    await axios.patch(`${BASE_API_URL}comparisons/${id}`, { name })
    return Promise.resolve('Comparison has been updated')
  } catch (err: any) {
    const reason = 'Failed to update saved comparison'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const removeSavedComparison = async (id) => {
  try {
    await axios.delete(`${BASE_API_URL}comparisons/${id}`)
    return Promise.resolve('Comparison has been deleted')
  } catch (err: any) {
    const reason = 'Failed to delete saved comparison'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const expungeSavedComparison = async ({ id }) => {
  try {
    await axios.post(`${BASE_API_URL}comparisons/${id}/expunge`)
    return Promise.resolve('Comparison has been expunged')
  } catch (err: any) {
    const reason = 'Failed to expunge saved comparison'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const restoreSavedComparison = async ({ id }) => {
  try {
    await axios.post(`${BASE_API_URL}comparisons/${id}/restore`)
    return Promise.resolve('Comparison has been restored')
  } catch (err: any) {
    const reason = 'Failed to restore saved comparison'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const emailSavedComparison = async ({ id, values }: { id: string | number; values }) => {
  try {
    await axios.post(`${BASE_API_URL}comparisons/${id}/email`, values)
    message.success('Comparison will be emailed shortly')
    return Promise.resolve()
  } catch (err: any) {
    const reason = 'Failed to send saved comparison'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const exportSavedComparison = async ({
  id,
  format = FileFormat.XLS,
}: {
  id: string | number
  format: FileFormat
}) => {
  try {
    const res = await axios.post(
      `${BASE_API_URL}comparisons/${id}/export`,
      { format },
      {
        responseType: 'arraybuffer',
      },
    )
    return res
  } catch (err: any) {
    const reason = 'Failed to export saved comparison'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const approvePolicy = async ({ policyId, values }) => {
  try {
    await axios.post(`${BASE_API_URL}policies/${policyId}/approve`, values)
    message.success('The policy section approval has been sent.')
    return Promise.resolve()
  } catch (err: any) {
    const reason = 'Failed to post policy approval request, please try again later.'
    const status = err ? err.status : null
    message.error(reason)
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}

export const logUserActivities = async (name: UserActivityLog) => {
  try {
    await axios.post(`${BASE_API_URL}user-activities`, { name })
  } catch (err: any) {
    const reason = 'Failed to patch user activities'
    const status = err ? err.status : null
    switch (status) {
      case StatusCodes.NOT_FOUND:
        throw new Error(reason)
      default:
        throw new Error(reason)
    }
  }
}
