import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Menu } from 'antd'
import type { MenuProps } from 'antd'
import './header-menu.less'
import { RoutePaths } from 'types/enums'
import auth from 'services/auth'

const SUPPORT_SERVICES_URL = process.env.REACT_APP_SUPPORT_SERVICES_URL

const HeaderMenu: React.FC = () => {
  const [current, setCurrent] = useState('home')

  const handleClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key)
  }

  const generateLinks = (path: string) => {
    if (auth.isLoggedIn && auth.supportServicesKey) {
      // return `${SUPPORT_SERVICES_URL}${path}${auth.supportServicesKey}`
      return `${SUPPORT_SERVICES_URL}${path}`
    } else {
      return `${SUPPORT_SERVICES_URL}${path}`
    }
  }

  const menuItems: MenuProps['items'] = [
    {
      key: 'dashboard',
      label: <Link to={RoutePaths.DASHBOARD}>DASHBOARD</Link>,
      className: 'header-menu-item',
    },
    {
      key: 'resources',
      label: 'RESOURCES',
      className: 'header-dropdown-menu-item',
      children: [
        {
          key: 'claims-comparison',
          label: (
            <a href='https://www.claimscomparison.com/' target='_blank' rel='noopener noreferrer'>
              Claims Comparison
            </a>
          ),
        },
        {
          key: 'policy-library',
          label: (
            <a href={generateLinks(`policy-library`)} target='_blank' rel='noopener noreferrer'>
              Policy Library
            </a>
          ),
        },
        {
          key: 'financial-strength-rating',
          label: (
            <a href={generateLinks('financial-ratings')} target='_blank' rel='noopener noreferrer'>
              Financial Strength Rating
            </a>
          ),
        },
        {
          key: 'case-law',
          label: (
            <a href={generateLinks('case-law')} target='_blank' rel='noopener noreferrer'>
              Case Law
            </a>
          ),
        },
        {
          key: 'glossary',
          label: (
            <a
              href={`${SUPPORT_SERVICES_URL}insurance-glossary`}
              target='_blank'
              rel='noopener noreferrer'
            >
              Glossary
            </a>
          ),
        },
      ],
    },
  ]

  return (
    <div className='header-menu'>
      {auth.isLoggedIn && (
        <Menu
          onClick={handleClick}
          selectedKeys={[current]}
          mode={'horizontal'}
          items={menuItems}
        />
      )}
    </div>
  )
}

export default HeaderMenu
