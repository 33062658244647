/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode } from 'react'
import { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import Spinner from 'components/spinner/Spinner'
import { useWindowResize } from 'components/hooks'

import { initialize, initializeApp } from 'slices/app'
import { useAppDispatch, useAppSelector } from '../../store'

import './App.less'
import 'styles/index.less'

interface AppProps {
  children: ReactNode
}

const options = {
  enableMouseEvents: true,
  scrollAngleRanges: [{ start: 300 }, { end: 60 }, { start: 120, end: 240 }],
}

const App: React.FC<AppProps> = ({ children }) => {
  const dispatch = useAppDispatch()
  const initialized = useAppSelector((state) => state.app.initialized)
  const windowSize = useWindowResize()

  useEffect(() => {
    const init = async () => {
      await dispatch(initialize())
      dispatch(initializeApp())
    }
    init()
  }, [])

  if (!initialized) {
    return <Spinner />
  }

  return (
    <div data-testid='app'>
      {windowSize.isTablet || windowSize.isMobile ? (
        <DndProvider backend={TouchBackend} options={options}>
          {children}
        </DndProvider>
      ) : (
        <DndProvider backend={HTML5Backend}>{children}</DndProvider>
      )}
    </div>
  )
}

export default App
