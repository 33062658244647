export const categories = [
  {
    id: 2,
    name: 'Personal',
    classes: [
      {
        id: 106,
        name: 'Caravan/Trailer',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 62,
            name: 'Caravan/Trailer',
          },
        ],
      },
      {
        id: 109,
        name: 'Home & Contents',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 59,
            name: 'Home & Contents',
          },
        ],
      },
      {
        id: 22,
        name: 'Landlords',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 84,
            name: 'Landlords',
          },
        ],
      },
      {
        id: 53,
        name: 'Leisure Travel',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 1677,
            name: 'Cover',
          },
          {
            id: 1731,
            name: 'Covid-19 Cover',
          },
          {
            id: 1678,
            name: 'General',
          },
        ],
      },
      {
        id: 108,
        name: 'Private Boat',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 67,
            name: 'Private Boat',
          },
        ],
      },
      {
        id: 107,
        name: 'Private Motor',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 42,
            name: 'Private Motor (including Prestige)',
          },
        ],
      },
      {
        id: 21,
        name: 'Strata',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 1595,
            name: 'Strata',
          },
        ],
      },
    ],
  },
  {
    id: 1,
    name: 'Commercial',
    classes: [
      {
        id: 26,
        name: 'Commercial Motor',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 102,
            name: 'Commercial/Fleet/Heavy Motor',
          },
        ],
      },
      {
        id: 2,
        name: 'Commercial Packs/ISR',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 267,
            name: '.Available Policy Sections',
          },
          {
            id: 1732,
            name: '.General Conditions Definitions and Exclusions',
          },
          {
            id: 4,
            name: 'Burglary/Theft - Business Packs',
          },
          {
            id: 2,
            name: 'Business Interruption - Business Packs',
          },
          {
            id: 441,
            name: 'Commercial Motor - Business Packs',
          },
          {
            id: 265,
            name: 'Electronic Equipment - Business Packs',
          },
          {
            id: 5,
            name: 'Liability - Business Packs',
          },
          {
            id: 122,
            name: 'Machinery Breakdown - Business Packs',
          },
          {
            id: 3,
            name: 'Material Damage - Business Packs',
          },
          {
            id: 50,
            name: 'Money - Business Packs',
          },
          {
            id: 339,
            name: 'Personal Accident - Business Packs',
          },
        ],
      },
      {
        id: 12,
        name: 'Contract Works',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 71,
            name: 'Contract Works - Liability',
          },
          {
            id: 70,
            name: 'Contract Works - Property',
          },
        ],
      },
      {
        id: 29,
        name: 'Contractor Plant',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 292,
            name: 'Material Damage and Road Risks',
          },
        ],
      },
      {
        id: 118,
        name: 'Corporate Travel',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 1661,
            name: 'A: Available Policy Sections',
          },
          {
            id: 1662,
            name: 'B: General Definitions, Conditions and Exclusions',
          },
          {
            id: 1733,
            name: 'C: Covid-19 Cover',
          },
          {
            id: 1667,
            name: 'D: Overseas Medical Expenses',
          },
          {
            id: 1666,
            name: 'E: Additional Expenses Non-Medical',
          },
          {
            id: 1675,
            name: 'F: Evacuation & Personal Safety Assist & Trauma Event',
          },
          {
            id: 1665,
            name: 'G: Luggage & Personal Effects',
          },
          {
            id: 1668,
            name: 'H: Personal Accident',
          },
          {
            id: 1676,
            name: 'I: Family Assist Benefits',
          },
          {
            id: 1663,
            name: 'J: Kidnap & Ransom',
          },
          {
            id: 1664,
            name: 'K: Legal Liability & Identity Theft',
          },
          {
            id: 1669,
            name: 'L: Worldwide Assist',
          },
        ],
      },
      {
        id: 81,
        name: 'Cyber Risks',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 503,
            name: 'Available Policy Sections',
          },
          {
            id: 496,
            name: 'Section 1 Cyber Event Response Costs',
          },
          {
            id: 495,
            name: 'Section 2 Third Party Loss',
          },
          {
            id: 494,
            name: 'Section 3 First Party Loss',
          },
          {
            id: 501,
            name: 'Section 4 General Conditions and Exclusions',
          },
          {
            id: 1692,
            name: 'Section 5 Cyber Crime and Social Engineering Fraud',
          },
        ],
      },
      {
        id: 122,
        name: 'Engineering',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 1681,
            name: 'Available Policy Sections',
          },
          {
            id: 1682,
            name: 'Breakdown of Equipment',
          },
          {
            id: 1683,
            name: 'Business Interruption',
          },
          {
            id: 1684,
            name: 'Deterioration of Stock',
          },
          {
            id: 1685,
            name: 'Restoration of Data',
          },
        ],
      },
      {
        id: 22,
        name: 'Landlords',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 84,
            name: 'Landlords',
          },
        ],
      },
      {
        id: 50,
        name: 'Liability',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 229,
            name: 'Liability',
          },
        ],
      },
      {
        id: 31,
        name: 'Marine',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 1725,
            name: 'Marine Carriers',
          },
          {
            id: 155,
            name: 'Marine Transit',
          },
        ],
      },
      {
        id: 82,
        name: 'Product Recall',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 1707,
            name: 'Cover Product Recall',
          },
          {
            id: 1708,
            name: 'Policy Definitions and Conditions',
          },
          {
            id: 1709,
            name: 'Policy Exclusions',
          },
        ],
      },
      {
        id: 21,
        name: 'Strata',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 1595,
            name: 'Strata',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    name: 'Rural',
    classes: [
      {
        id: 121,
        name: 'Crop',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 1628,
            name: 'Cotton Crops',
          },
          {
            id: 111,
            name: 'Crops/Harvest',
          },
          {
            id: 1679,
            name: 'Horticulture/Orchard',
          },
        ],
      },
      {
        id: 3,
        name: 'Rural Packs',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 1638,
            name: 'Available Policy Sections',
          },
          {
            id: 242,
            name: 'Boat - Rural Packs',
          },
          {
            id: 243,
            name: 'Business Interruption - Rural Packs',
          },
          {
            id: 244,
            name: 'Commercial Farm Motor - Rural Packs',
          },
          {
            id: 245,
            name: 'Electronic Equipment - Rural Packs',
          },
          {
            id: 246,
            name: 'Farm Business Tax Audit',
          },
          {
            id: 248,
            name: 'Farm Property',
          },
          {
            id: 249,
            name: 'General Property - Rural Packs',
          },
          {
            id: 250,
            name: 'Home & Contents - Rural Packs',
          },
          {
            id: 252,
            name: 'Liability - Rural Packs',
          },
          {
            id: 1726,
            name: 'Livestock',
          },
          {
            id: 256,
            name: 'Machinery Breakdown - Rural Packs',
          },
          {
            id: 257,
            name: 'Personal Accident - Rural Packs',
          },
          {
            id: 258,
            name: 'Private Motor - Rural Packs',
          },
          {
            id: 259,
            name: 'Theft - Rural Packs',
          },
          {
            id: 260,
            name: 'Transit - Rural Packs',
          },
          {
            id: 261,
            name: 'Working Dogs',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Professional',
    classes: [
      {
        id: 126,
        name: 'Directors and Officers',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 108,
            name: 'Directors and Officers',
          },
        ],
      },
      {
        id: 110,
        name: 'Management Liability',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 466,
            name: 'Available Policy Sections',
          },
          {
            id: 426,
            name: 'Company Liability',
          },
          {
            id: 428,
            name: 'Crime',
          },
          {
            id: 427,
            name: 'D&O',
          },
          {
            id: 429,
            name: 'EPL',
          },
          {
            id: 430,
            name: 'Internet Liability',
          },
          {
            id: 432,
            name: 'Misc PI',
          },
          {
            id: 433,
            name: 'Statutory Liability',
          },
          {
            id: 435,
            name: 'Superannuation Trustees Liability',
          },
          {
            id: 434,
            name: 'Taxation Investigation',
          },
        ],
      },
      {
        id: 125,
        name: 'Professional Indemnity',
        country: {
          id: 1,
          name: 'Australia',
          code: 'AU',
        },
        covers: [
          {
            id: 104,
            name: 'Professional Indemnity',
          },
        ],
      },
    ],
  },
]
