import { useState, useEffect } from 'react'
import { BreakPoints } from 'types/enums'

const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState<{
    width: number | undefined
    height: number | undefined
    isMobile?: boolean
    isTablet?: boolean
  }>({
    width: undefined,
    height: undefined,
    isMobile: false,
    isTablet: false,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < BreakPoints.MOBILE,
        isTablet: window.innerWidth < BreakPoints.TABLET,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}

export default useWindowResize
