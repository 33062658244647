export const themeMock = [
  {
    id: 1,
    label: 'Theme 1',
    description: '',
    value:
      '{"colorTitle":"#0f9daf","colorBg1":"#bbbcbc","colorBg2":"#69696c","colorBar":"#f1c400"}',
    scope: 'COMPANY',
  },
  {
    id: 2,
    label: 'Theme 2',
    description: '',
    value:
      '{"colorTitle":"#005350","colorBg1":"#00827d","colorBg2":"#003c3a","colorBar":"#7d7761"}',
    scope: 'COMPANY',
  },
  {
    id: 3,
    label: 'Theme 3',
    description: '',
    value:
      '{"colorTitle":"#003366","colorBg1":"#2e5882","colorBg2":"#002141","colorBar":"#cc0033"}',
    scope: 'COMPANY',
  },
  {
    id: 4,
    label: 'Theme 4',
    description: '',
    value:
      '{"colorTitle":"#b8b1ac","colorBg1":"#3d215e","colorBg2":"#1c0f2b","colorBar":"#e15e83"}',
    scope: 'COMPANY',
  },
  {
    id: 5,
    label: 'Theme 5',
    description: '',
    value:
      '{"colorTitle":"#7c8ac4","colorBg1":"#bcc8d5","colorBg2":"#565c62","colorBar":"#f58221"}',
    scope: 'COMPANY',
  },
  {
    id: 6,
    label: 'Theme 6',
    description: '',
    value:
      '{"colorTitle":"#878a8f","colorBg1":"#335979","colorBg2":"#003057","colorBar":"#00a4cb"}',
    scope: 'COMPANY',
  },
  {
    id: 7,
    label: 'Theme 7',
    description: '',
    value:
      '{"colorTitle":"#de7d44","colorBg1":"#6facde","colorBg2":"#00263e","colorBar":"#6facde"}',
    scope: 'COMPANY',
  },
  {
    id: 8,
    label: 'Theme 8',
    description: '',
    value:
      '{"colorTitle":"#0178ae","colorBg1":"#0178ae","colorBg2":"#165e50","colorBar":"#fba450"}',
    scope: 'COMPANY',
  },
  {
    id: 9,
    label: 'Theme 9',
    description: '',
    value:
      '{"colorTitle":"#b6b7b8","colorBg1":"#006cb7","colorBg2":"#012a56","colorBar":"#006cb7"}',
    scope: 'COMPANY',
  },
  {
    id: 10,
    label: 'Theme 10',
    description: '',
    value:
      '{"colorTitle":"#6c757e","colorBg1":"#00a65e","colorBg2":"#07767e","colorBar":"#114b9b"}',
    scope: 'COMPANY',
  },
  {
    id: 11,
    label: 'Tudor theme',
    description: '',
    value:
      '{"colorTitle":"#878a8f","colorBg1":"#3244a0","colorBg2":"#00116d","colorBar":"#8cbcd7"}',
    scope: 'COMPANY',
  },
  {
    id: 12,
    label: 'Brookvale theme',
    description: '',
    value:
      '{"colorTitle":"#565c62","colorBg1":"#d31145","colorBg2":"#a80c37","colorBar":"#3b3e43"}',
    scope: 'COMPANY',
  },
  {
    id: 13,
    label: 'EBM theme',
    description: '',
    value:
      '{"colorTitle":"#3b3e43","colorBg1":"#da011a","colorBg2":"#b11116","colorBar":"#000000"}',
    scope: 'COMPANY',
  },
  {
    id: 14,
    label: 'Findex theme',
    description: '',
    value:
      '{"colorTitle": "#D43D27","colorBg1": "#2E3C49","colorBg2": "#1B1F22","colorBar": "#D43D27"}',
    scope: 'COMPANY',
  },
  {
    id: 15,
    label: 'Atlas Brokers theme',
    description: '',
    value:
      '{"colorTitle": "#1e2f6e","colorBg1": "#0099DA","colorBg2": "#0099DA","colorBar": "#0099DA"}',
    scope: 'COMPANY',
  },
  {
    id: 16,
    label: 'PSC Connect theme',
    description: '',
    value:
      '{"colorTitle":"#252525","colorBg1":"#246eb7","colorBg2":"#246eb7","colorBar":"#6c757e"}',
    scope: 'COMPANY',
  },
  {
    id: 17,
    label: 'McKillops',
    description: '',
    value:
      '{"colorTitle":"#4d4d4d","colorBg1":"#005eb8","colorBg2":"#005eb8","colorBar":"#5dbaa9"}',
    scope: 'COMPANY',
  },
  {
    id: 18,
    label: 'Brindabella',
    description: '',
    value:
      '{"colorTitle":"#4f5760","colorBg1":"#ffb347","colorBg2":"#e5a13f","colorBar":"#b8cbc8"}',
    scope: 'COMPANY',
  },
  {
    id: 19,
    label: 'Arcuri',
    description: '',
    value:
      '{"colorTitle":"#333333","colorBg1":"#850037","colorBg2":"#99003f","colorBar":"#333333"}',
    scope: 'COMPANY',
  },
  {
    id: 20,
    label: 'Aviso',
    description: '',
    value:
      '{"colorTitle":"#6d6e71","colorBg1":"#ed1c24","colorBg2":"#ffcd03","colorBar":"#6d6e71"}',
    scope: 'COMPANY',
  },
  {
    id: 21,
    label: 'Aon Theme',
    description: '',
    value:
      ' {"colorTitle":"#46535e","colorBg1":"#eb0017","colorBg2":"#eb0017","colorBar":"#262836"}',
    scope: 'COMPANY',
  },
]

export const themeParser = (theme) => {
  if (theme?.body) {
    // if theme.body exist we don't need to convert theme object
    return theme
  }

  return {
    covers: [
      {
        bgColor: [theme.colorBg1, theme.colorBg2],
      },
    ],
    body: {
      color: 'rgba(0, 0, 0, 0.8)',
      font: "Roboto, 'Liberation Sans', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif",
      size: '15px',
    },
    link: {
      color: '#f1c400',
      active: '#f1c400',
      hover: '#f1c400',
      visited: '#f1c400',
    },
    headings: {
      h1: {
        color: theme.colorTitle,
        font: "Roboto, 'Liberation Sans', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif",
        size: '1.6em',
      },
      h2: {
        color: '#fff',
        font: "Roboto, 'Liberation Sans', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif",
        size: '1.2em',
      },
      h3: {
        color: '#000',
        font: "Roboto, 'Liberation Sans', 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif",
        size: '1.1em',
      },
      pageHeading: {
        color: '#fff',
      },
    },
    visuals: {
      divider: {
        color: theme.colorBar,
        width: '2px', // Default width
      },
    },
  }
}
