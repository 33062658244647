import { ReactNode } from 'react'
import { Layout as AntLayout } from 'antd'
import cx from 'classnames'
import Footer from 'components/footer/Footer'
import Header from 'components/header/Header'

import './layout.less'

interface LayoutProps {
  children: ReactNode
  className?: string | string[]
}

const { Content } = AntLayout

const Layout: React.FC<LayoutProps> = ({ children, className }) => {
  return (
    <AntLayout className='layout-container'>
      <Header />
      <Content className={cx('site-container', className)}>{children}</Content>
      <Footer />
    </AntLayout>
  )
}

export default Layout
