/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { themeMock, themeParser } from 'scenes/comparison/scenes/report-preview/theme'
import { IRootState } from 'store'

export interface IReportState {
  insuredName?: string
  filter: any
  theme: any
  themes: any
}

export const initialState: IReportState = {
  theme: themeMock[0],
  themes: themeMock,
  filter: null,
  insuredName: 'Insured Business Name',
}

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setTheme: (state: IReportState, { payload }: PayloadAction<string>) => {
      const found = state.themes.find((t) => t.id === payload)
      state.theme = found
    },
    setThemes: (state: IReportState, { payload }: PayloadAction<string>) => {
      state.theme = payload
    },
    setInsuredName: (state: IReportState, { payload }: PayloadAction<string>) => {
      state.insuredName = payload
    },
  },
})

export const getTheme = createSelector(
  (state: IRootState) => state.report.theme,
  (reportTheme) => themeParser(JSON.parse(reportTheme.value)),
)
export const getThemes = (state) => state.report.themes.map((theme) => theme.value)

export const { setTheme, setThemes, setInsuredName } = reportSlice.actions
export default reportSlice.reducer
