/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { StatusCodes } from 'http-status-codes'
import qs from 'qs'

const MSG_SUCCESS_RESET_PASSWORD =
  'Success! An email has been sent containing instructions to reset your password.'
const MSG_ERROR = 'An unexpected error occurred. Please try again later.'
const MSG_ERROR_LOGIN_FAIL = 'Incorrect username or password.'
const MSG_ERROR_USER_PROFILE_FAIL = 'Failed to access user profile.'
const MSG_ERROR_NOT_FOUND = "We couldn't find this account."

const API_LMI_AUTH: string = process.env.REACT_APP_API_LMI_AUTH || ''
const API_LMI_USER: string = process.env.REACT_APP_API_LMI_USER || ''
const API_RESET_PASSWORD: string = process.env.REACT_APP_API_RESET_PASSWORD || ''
const LMI_PROJECT_ID: string = process.env.REACT_APP_PROJECT_ID || ''
const BASE_API_URL: string = process.env.REACT_APP_BASE_API_URL || ''

export const loginAsync = createAsyncThunk(
  'auth/login',
  async ({ username, password }: { username: string; password: string }, { rejectWithValue }) => {
    const url = API_LMI_AUTH
    const data = {
      username,
      password,
      grant_type: 'password',
      client_id: LMI_PROJECT_ID,
    }

    try {
      const resToken = await axios.post(url, qs.stringify(data))
      const token = resToken.data.access_token
      const resUser = await axios.get(API_LMI_USER, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return {
        token,
        expiry: new Date().getTime() + resToken.data.expires_in,
        user: resUser.data,
      }
    } catch (err: any) {
      const status = err ? err.status : null
      switch (status) {
        case StatusCodes.UNAUTHORIZED:
          return rejectWithValue(MSG_ERROR_USER_PROFILE_FAIL)
        case StatusCodes.BAD_REQUEST:
          return rejectWithValue(MSG_ERROR_LOGIN_FAIL)
        default:
          return rejectWithValue(MSG_ERROR)
      }
    }
  },
)

export const loginWithEncryptedDataAsync = createAsyncThunk(
  'auth/encrypted-data-login',
  async ({ token, grantType }: { token: string; grantType: string }, { rejectWithValue }) => {
    const url = API_LMI_AUTH
    const data = {
      grant_type: grantType,
      encrypted_data: token,
      client_id: LMI_PROJECT_ID,
    }

    try {
      const resToken = await axios.post(url, qs.stringify(data))
      const token = resToken.data.access_token
      const resUser = await axios.get(API_LMI_USER, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return {
        token,
        expiry: new Date().getTime() + resToken.data.expires_in,
        user: resUser.data,
      }
    } catch (err: any) {
      const status = err ? err.status : null
      switch (status) {
        case StatusCodes.UNAUTHORIZED:
          return rejectWithValue(MSG_ERROR_USER_PROFILE_FAIL)
        case StatusCodes.BAD_REQUEST:
          return rejectWithValue(MSG_ERROR_LOGIN_FAIL)
        default:
          return rejectWithValue(MSG_ERROR)
      }
    }
  },
)

export const fetchUserAccountAsync = createAsyncThunk(
  'auth/fetch-user',
  async (token: string, { rejectWithValue }) => {
    try {
      const resUser = await axios.get(`${BASE_API_URL}user`)
      return resUser.data
    } catch (err: any) {
      const status = err ? err.status : null
      switch (status) {
        case StatusCodes.UNAUTHORIZED:
          return rejectWithValue(MSG_ERROR_USER_PROFILE_FAIL)
        case StatusCodes.BAD_REQUEST:
          return rejectWithValue(MSG_ERROR_LOGIN_FAIL)
        default:
          return rejectWithValue(MSG_ERROR)
      }
    }
  },
)

export const resetPasswordAsync = createAsyncThunk(
  'auth/reset-password',
  async ({ username }: { username: string }, { rejectWithValue }) => {
    const url = `${API_RESET_PASSWORD}&userName=${username}`
    try {
      await axios.post(url)
      return MSG_SUCCESS_RESET_PASSWORD
    } catch (err: any) {
      const status = err ? err.status : null
      switch (status) {
        case StatusCodes.NOT_FOUND:
          return rejectWithValue(MSG_ERROR_NOT_FOUND)
        default:
          return rejectWithValue(MSG_ERROR)
      }
    }
  },
)
