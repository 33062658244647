import { Col, Row } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faYoutube, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import lmiNightLogo from 'assets/images/au-night-logo.png'
import './footer.less'

const Footer: React.FC = () => (
  <footer className='site-footer' data-testid='site-footer'>
    <div className='site-footer-container'>
      <Row>
        <Col xs={0} md={2} className='lmi-logo'>
          <img src={lmiNightLogo} alt='logo' />
          <br />
          This site is brought to you by the LMI Group.
        </Col>
        <Col xs={24} md={22} className='site-footer-nav-container'>
          <Row>
            <Col xs={14} md={18}>
              <ul className='site-footer-nav' data-testid='service-links'>
                <li className='site-footer-nav-item'>
                  <a href='https://www.lmigroup.io/' target='_blank' rel='noopener noreferrer'>
                    OUR SERVICES
                  </a>
                </li>
                <li className='site-footer-nav-item'>
                  <a
                    href='https://www.lmigroup.io/our-team/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    OUR EXPERTS
                  </a>
                </li>
                <li className='site-footer-nav-item'>
                  <a
                    href='https://lmigroup.io/terms-conditions/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    TERMS &amp; CONDITIONS
                  </a>
                </li>
                <li className='site-footer-nav-item'>
                  <a
                    href='https://lmigroup.io/financial-services-guide/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    FINANCIAL SERVICES GUIDE
                  </a>
                </li>
                <li className='site-footer-nav-item'>
                  <a href='https://lmigroup.io/privacy/' target='_blank' rel='noopener noreferrer'>
                    PRIVACY &amp; SECURITY
                  </a>
                </li>
                <li className='site-footer-nav-item'>
                  <a
                    href='https://lmigroup.io/complaints/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    COMPLAINTS
                  </a>
                </li>
                <li className='site-footer-nav-item'>
                  <a
                    href='https://lmigroup.io/contact-us#Contact'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    CONTACT US
                  </a>
                </li>
              </ul>
            </Col>
            <Col xs={10} md={6}>
              <ul className='site-footer-social-links' data-testid='social-links'>
                <li className='site-footer-social-link-item'>
                  <a
                    href='https://www.facebook.com/LMIGroupPtyLtd/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
                <li className='site-footer-social-link-item'>
                  <a
                    href='https://www.youtube.com/user/LMIGROUP'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                </li>
                <li className='site-footer-social-link-item'>
                  <a
                    href='https://www.linkedin.com/company/lmi-group/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className='site-footer-copyright' data-testid='footer-copyright'>
                &copy; 1999-{new Date().getFullYear()} LMI Group Pty Ltd | ABN 69 086 256 171 | AFS
                Licence No. 283777
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </footer>
)

export default Footer
