import { Component, ReactNode, ErrorInfo } from 'react'
import './error-boundary.less'

interface ErrorBoundaryProps {
  children?: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true })
    console.error(error, info)
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state
    if (hasError) {
      return (
        <div className='error-boundary'>
          <div className='error-boundary-icon' />
        </div>
      )
    }
    return children
  }
}
